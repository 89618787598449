import 'bootstrap';
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Home from './components/Home.vue';
import Sucursal from './components/Sucursal.vue';
import Menu from './components/Menu.vue';
import Promociones from './components/Promociones.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueSmoothScroll from 'vue3-smooth-scroll'

const app = createApp(App)

app.use(VueSmoothScroll)
app.use(createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', name: 'home' , component: Home},
        {path: '/:sucursal', name: 'sucursal' , component: Sucursal, params: true},
        {path: '/:sucursal/menu', name: 'menu' , component: Menu, params: true},
        {path: '/:sucursal/promociones', name: 'promociones' , component: Promociones, params: true}
    ]
}))

app.mount('#app')
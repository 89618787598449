<template>
  <router-view></router-view>
</template>

<script >
export default {
  name: 'App',
  data(){
    return{
      ano: ''
    }
  },
  created(){
    this.ano = new Date().getFullYear()
  }
}
</script>
<template>
    <div class="fondo menu">
         <!-- Your Chat Plugin code -->
    <div id="fb-customer-chat" class="fb-customerchat">
    </div>
        <div class="logo p-5 d-md-none">
            <img @click="goBack()" src="/images/logo-rojo.png" class="img-fluid" alt="ITALOS" >
        </div>
        <div class="logo w-25 m-auto p-5 d-none d-md-block">
            <img @click="goBack()" src="/images/logo-rojo.png" class="img-fluid" alt="ITALOS" >
        </div>

        <div class="pdf">
            <VuePdfEmbed :rotation="360" :source="pdf" />
            <div class="d-block  sello-pdf text-center w-25">
                <img class="img-fluid" alt="ITALOS" src="/images/sello-negro.png" />
            </div>
        </div>
        <div class="footer end">
            <p class="txt-white secondary mb-0 pt-2 pb-2 text-center">{{ano}}, ITALO'S TRATTORÍA FAMILIAR ®</p>
        </div>
    </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
    name: 'PromocionesReader',
    components: {
        VuePdfEmbed,
    },
    data() {
        return {
            pdf: '',
            ano: new Date().getFullYear(),
            facebookID: ''
        }
    },
    created(){
        

        if(this.$route.params.sucursal == 'escobedo'){
            this.pdf = '/PDF/ITALOS_PROMOCIONES_ESCOBEDO.pdf'
            this.facebookID = '100474064729147'
        }
     
        if(this.$route.params.sucursal == 'vallehermoso'){
            this.pdf = '/PDF/ITALOS_PROMOCIONES_VH.pdf'
            this.facebookID = '125045837580469'
        }
        
    },
    mounted(){
        this.facebookApp(this.facebookID)
    },
    methods:{
        facebookApp(id){
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id",id );
            chatbox.setAttribute("attribution", "biz_inbox");
            delete window.FB
            window.fbAsyncInit = function() {
               
                window.FB.init({
                xfbml            : true,
                version          : 'v11.0'
                });
                
            };

            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },
        goBack(){
            this.$router.go(-1)
        }
    }
}
</script>

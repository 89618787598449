<template>
  <div class="fondo">
    <div class="justify-content-center d-flex container align-items-center d-md-none" style="height:100vh">
      <Transition name="slide-fade" key="panel1">
        <div v-if="show" class="card p-3 bkg-red text-center m-auto w-75 ">
          <div class="card-body">
            <div class="logo text-center p-2">
              <router-link to="/"><img class="img-fluid" alt="ITALOS" src="/images/logo-blanco.png" /></router-link>
            </div>
            <div>
              <div class="opciones-menu  d-grid gap-3">
                <p class="txt-white pt-3 mb-0 select-suc secondary">Selecciona tu sucursal</p>
                <router-link to="/escobedo" type="button" class="btn btn-light text-uppercase btn-lg rounded-pill">Escobedo-NL</router-link>
                <router-link to="/cumbres"  type="button" class="btn btn-light text-uppercase btn-lg rounded-pill">Cumbres-NL</router-link>
                <router-link to="/vallehermoso" type="button" class="btn btn-light text-uppercase btn-lg rounded-pill">Valle Hermoso - TAMPS</router-link>
                <a href="https://www.wansoft.net/ItalosPizza/FE.html" class="btn btn-outline-light text-uppercase btn-lg rounded-pill"><ReceiptTextIcon class="icon-italos" /> Factura en línea</a>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div class="container-fluid d-none d-md-block" style="height:100vh">
      <Transition name="slide-fade" key="panel2">
        <div class="row" v-if="show"  style="height:100vh">
          <div class="col-6 m-auto">
            <div class="d-flex m-auto w-50 h-auto">
              <img class="img-fluid" alt="ITALOS" src="/images/sello-blanco.png" />
            </div>
          </div>
          <div class="col-6 bkg-red text-center ">
            <div class="p-2 m-auto">
              <div class="logo text-center p-2 w-50 m-auto">
                <router-link to="/"><img class="img-fluid" alt="ITALOS" src="/images/logo-blanco.png" /></router-link>
              </div>
              <div>
                <div class="opciones-menu w-50 m-auto d-grid gap-3">
                  <p class="txt-white pt-3 mb-0 select-suc secondary">Selecciona tu sucursal</p>
                  <router-link to="/escobedo" type="button" class="btn btn-light text-uppercase btn-lg rounded-pill">Escobedo-NL</router-link>
                  <router-link to="/vallehermoso" type="button" class="btn btn-light text-uppercase btn-lg rounded-pill">Valle Hermoso - TAMPS</router-link>
                  <a href="https://www.wansoft.net/ItalosPizza/FE.html" class="btn btn-outline-light text-uppercase btn-lg rounded-pill"><ReceiptTextIcon class="icon-italos" /> Factura en línea</a>
                </div>
              </div>
            </div>
            <p class="txt-white secondary mb-0 pt-2 pb-2 text-center ">{{ano}}, ITALO'S TRATTORÍA FAMILIAR ®</p>
          </div>
          
        </div>
      </Transition>
    </div>
    <div class="d-block d-md-none sello text-center pt-2 w-25">
      <img class="img-fluid" alt="ITALOS" src="/images/sello-blanco.png" />
    </div>
    <div class="footer  d-md-none" >
      <p class="txt-white secondary mb-0 pt-2 pb-2 text-center">{{ano}}, ITALO'S TRATTORÍA FAMILIAR ®</p>
    </div>
  </div>
</template>

<script>
import ReceiptTextIcon from 'vue-material-design-icons/Receipt.vue';

export default {
  name: 'HomeItalos',
  components: {ReceiptTextIcon},
  data(){
    return{
      show: false,
      ano: new Date().getFullYear()
    }
  },
  mounted(){
    this.show = true;
    
  }
}
</script>

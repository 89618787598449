<template>
  <div class="fondo">
    <!-- Your Chat Plugin code -->
    <div id="fb-customer-chat" class="fb-customerchat">
    </div>

    <div class="row social-module d-md-none">
      <div class="col-12 pt-3 d-flex">
        <div class="pr-4 pl-4 social-box">
          <a v-if="sucursal == 'vallehermoso'" href="https://www.facebook.com/Italos.vh" ><FacebookIcon class="social-icons" /></a>
          <a v-else href="https://www.facebook.com/Italosmonterrey" ><FacebookIcon class="social-icons" /></a>
        </div>
        <div  class="pr-4 pl-4 social-box">
          <a v-if="sucursal == 'vallehermoso'" href="https://www.instagram.com/italos.vh/" ><InstagramIcon class="social-icons" /></a>
          <a v-else href="https://www.instagram.com/italos.mty/" ><InstagramIcon  class="social-icons"/></a>
        </div>
      </div>
    </div>
    <div class="justify-content-center d-flex container align-items-center d-md-none" style="height:100vh">
      <Transition name="slide-fade" key="panel1">
        <div v-if="show" class="card p-3 bkg-red text-center m-auto w-75 ">
          <div class="card-body">
            <div class="logo text-center p-2">
              <router-link to="/"><img class="img-fluid" alt="ITALOS" src="/images/logo-blanco.png" /></router-link>
            </div>
            <div>
              <div class="opciones-menu  d-grid gap-3">
                <p class="txt-white pt-3 mb-0 select-suc secondary">{{nombreSucursal}}</p>
                <p class="txt-white select-suc secondary">quepasa@italos.mx</p>
                <router-link :to="'/'+sucursal+'/promociones'" class="btn btn-light text-uppercase btn-lg rounded-pill "><BrightnessPercentIcon class="icon-italos" /> Promociones</router-link>
                <router-link :to="'/'+sucursal+'/menu'" class="btn btn-light text-uppercase btn-lg rounded-pill "><FoodForkDrinkIcon class="icon-italos" /> Menú</router-link>
                <a :href="armapizza" class="btn btn-light text-uppercase btn-lg rounded-pill "><PizzaIcon class="icon-italos" /> Arma tu Pizza</a>
                <a :href="whatsapp" class=" btn btn-light text-uppercase btn-lg rounded-pill"><WhatsappIcon class="icon-italos" /> Pide en Whatsapp</a>
                <a :href="'tel:'+telefono" class=" btn btn-light text-uppercase btn-lg rounded-pill"><PhoneIcon class="icon-italos" /> Llámanos</a>
                <a v-if="googlemaps" :href="googlemaps" class=" btn btn-light text-uppercase btn-lg rounded-pill"><MapMarkerIcon class="icon-italos" /> Nuestra ubicación</a>
                <button @click="goBack()" type="button" class="btn btn-outline-light  text-uppercase btn-lg rounded-pill"><SwapHorizontalIcon class="icon-italos" /> Cambiar de sucursal</button>
                <a href="https://www.wansoft.net/ItalosPizza/FE.html" class=" btn btn-outline-light text-uppercase btn-lg rounded-pill"><ReceiptTextIcon class="icon-italos" /> Factura en línea</a>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div class="container-fluid d-none d-md-block" style="height:100vh">
      
      <Transition name="slide-fade" key="panel2">
        <div class="row" v-if="show"  style="height:100vh">
          <div class="col-6 m-auto">
            <div class="d-flex m-auto w-50 h-auto">
              <img class="img-fluid" alt="ITALOS" src="/images/sello-blanco.png" />
            </div>
          </div>
          <div class="col-6 bkg-red text-center " style="overflow-y: scroll;height: 100vh;">

            <div class="p-2 m-auto">
              <div class="row">
                <div class="col-12 pt-3 d-flex  justify-content-center pb-2">
                  <div class="pr-4 pl-4 social-box">
                    <a v-if="sucursal == 'vallehermoso'" href="https://www.facebook.com/Italos.vh" ><FacebookIcon class="social-icons" /></a>
                    <a v-else href="https://www.facebook.com/Italosmonterrey" ><FacebookIcon class="social-icons" /></a>
                  </div>
                  <div  class="pr-4 pl-4 social-box">
                    <a v-if="sucursal == 'vallehermoso'" href="https://www.instagram.com/italos.vh/" ><InstagramIcon class="social-icons" /></a>
                    <a v-else href="https://www.instagram.com/italos.mty/" ><InstagramIcon  class="social-icons"/></a>
                  </div>
                </div>
              </div>
              <div class="logo text-center p-2 w-50 m-auto">
                <router-link to="/"><img class="img-fluid" alt="ITALOS" src="/images/logo-blanco.png" /></router-link>
              </div>
              <div>
                <div class="opciones-menu w-50 m-auto d-grid gap-3">
                  <p class="txt-white pt-3 mb-0 select-suc secondary">{{nombreSucursal}}</p>
                  <p class="txt-white select-suc secondary">quepasa@italos.mx</p>
                  <router-link :to="'/'+sucursal+'/promociones'" class="btn btn-light text-uppercase btn-lg rounded-pill "><BrightnessPercentIcon class="icon-italos" /> Promociones</router-link>
                  <router-link :to="'/'+sucursal+'/menu'" class="btn btn-light text-uppercase btn-lg rounded-pill "><FoodForkDrinkIcon class="icon-italos" /> Menú</router-link>
                  <a v-if="armapi1zza != ''" :href="armapizza" class="btn btn-light text-uppercase btn-lg rounded-pill "><PizzaIcon class="icon-italos" /> Arma tu Pizza</a>
                  <a v-if="whatsapp != ''" :href="whatsapp" class=" btn btn-light text-uppercase btn-lg rounded-pill"><WhatsappIcon class="icon-italos" /> Pide en Whatsapp</a>
                  <a :href="'tel:'+telefono" class=" btn btn-light text-uppercase btn-lg rounded-pill"><PhoneIcon class="icon-italos" /> Llámanos</a>
                  <a v-if="googlemaps != ''" :href="googlemaps" class=" btn btn-light text-uppercase btn-lg rounded-pill"><MapMarkerIcon class="icon-italos" /> Nuestra ubicación</a>
                  <button @click="goBack()" type="button" class="btn btn-outline-light  text-uppercase btn-lg rounded-pill"><SwapHorizontalIcon class="icon-italos" /> Cambiar de sucursal</button>
                  <a href="https://www.wansoft.net/ItalosPizza/FE.html" class=" btn btn-outline-light text-uppercase btn-lg rounded-pill"><ReceiptTextIcon class="icon-italos" /> Factura en línea</a>
                </div>
              </div>
            </div>
            <p class="txt-white secondary mb-0 pt-2 pb-2 text-center ">{{ano}}, ITALO'S TRATTORÍA FAMILIAR ®</p>
          </div>
        </div>
      </Transition>
    </div>
    <div class="d-block sello text-center pt-2 w-25 d-md-none">
      <img class="img-fluid" alt="ITALOS" src="/images/sello-blanco.png" />
    </div>
    <div class="footer  d-md-none">
      <p class="txt-white secondary mb-0 pt-2 pb-2 text-center">{{ano}}, ITALO'S TRATTORÍA FAMILIAR ®</p>
    </div>
  </div>
</template>

<script>
import WhatsappIcon           from 'vue-material-design-icons/Whatsapp.vue';
import PhoneIcon              from 'vue-material-design-icons/Phone.vue';
import MapMarkerIcon          from 'vue-material-design-icons/MapMarker.vue';
import PizzaIcon              from 'vue-material-design-icons/Pizza.vue';
import SwapHorizontalIcon     from 'vue-material-design-icons/SwapHorizontal.vue';
import ReceiptTextIcon        from 'vue-material-design-icons/Receipt.vue';
import FoodForkDrinkIcon      from 'vue-material-design-icons/FoodForkDrink.vue';
import InstagramIcon          from 'vue-material-design-icons/Instagram.vue';
import FacebookIcon           from 'vue-material-design-icons/Facebook.vue';
import BrightnessPercentIcon  from 'vue-material-design-icons/BrightnessPercent.vue';

export default {
  name: 'SucursalItalos',
  components: {WhatsappIcon,PhoneIcon,MapMarkerIcon,PizzaIcon,SwapHorizontalIcon,ReceiptTextIcon,FoodForkDrinkIcon,InstagramIcon,FacebookIcon,BrightnessPercentIcon},
  data(){
    return{
      show: false,
      nombreSucursal: '',
      whatsapp:'',
      sucursal: '',
      googlemaps: '',
      telefono: '',
      armapizza: '',
      ano: new Date().getFullYear(),
      facebookID: ''
    }
  },
  created(){
    if(this.$route.params.sucursal != 'vallehermoso'){
        this.nombreSucursal = this.$route.params.sucursal + ' - NL'
        this.facebookID = '100474064729147'
        if(this.$route.params.sucursal == 'escobedo'){
            this.telefono = '8113605332'
            this.whatsapp = 'https://api.whatsapp.com/send?phone=528180957853&text=Hola!%20Quisiera%20Hacer%20Un%20Pedido!'
            this.googlemaps = 'https://g.page/Italos-pizza-mty?share'
            //this.armapizza = 'https://www.wansoft.net/Tienda.Wansoft/Home/Index?id=7RWACe1PCuo='
        }else{
       //  this.telefono = '8113574462'
       //  this.googlemaps = 'https://goo.gl/maps/mrPWrPyEushjNVou5'
       //  this.whatsapp = 'https://api.whatsapp.com/send?phone=528119055897&text=Hola!%20Quisiera%20Hacer%20Un%20Pedido!'
        }
    }else{
        this.facebookID = '125045837580469'
        this.googlemaps = "https://www.google.com/maps/place/Italo's+Pizza+Trattoria+Familiar/data=!4m7!3m6!1s0x866559bc13245bef:0x349ddeb019825db7!8m2!3d25.6742661!4d-97.8140782!16s%2Fg%2F1tjdgmls!19sChIJ71skE7xZZYYRt12CGbDenTQ?authuser=0&hl=en&rclk=1"
        this.nombreSucursal = 'Valle Hermoso - TAMPS'
        this.telefono = '8948422967'
        this.armapizza = 'https://www.wansoft.net/Tienda.Wansoft/Home/Index?id=ae3UmVGowAM='
    }
    
    this.sucursal = this.$route.params.sucursal
  },
  mounted(){
    this.show = true
    this.facebookApp(this.facebookID)
  },
  methods:{
    facebookApp(id){
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", id);
      chatbox.setAttribute("attribution", "biz_inbox");
      delete window.FB
      window.fbAsyncInit = function() {
       
        window.FB.init({
          xfbml            : true,
          version          : 'v11.0'
        });
        
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>
